export const AUTH = {
	SENDSMS: '/auth/v1/sms/send/',
	SMS_VERIFY: '/auth/v1/sms/verify/',
	LOGOUT: '/auth/v1/logout/',
	TOKEN_REFRESH: '/auth/v1/token/refresh/',
	PHARMACY_REGISTER: '/general/v2/pharmacy',
	USER_LOGOUT: '/auth/v1/logout'
};

export const API_CART = {
	CHECKOUT_INIT: '/general/v1/checkoutInitiated',
	USER_CART_GET: '/general/v1/userCartDetails/?_return_details=1',
	USER_CART_MODIFY: '/general/v1/userCart/',
	ADD_PRESCRIPTION_TO_CART: '/general/v1/addPrescriptionToCart',
	GET_DELIVERY_SCHEDULE: '/v1/timeSlots',
	CHECK_COUPON_CODE: `/general/v1/checkCouponEligibility`,
	ORDER_CREATE_FROM_CART: '/general/v1/orderCreateFromCart',
	CONFIRM_ORDER: '/general/v1/confirmOrder',
	UPDATE_CART_LOCATION: '/general/v1/updateCartLocation/?_return_details=1',
	REMOVE_PRESCRIPTION_FROM_CART: (key: string) => `/general/v1/deletePrescriptionFromCart/?prescriptionKey=${key}`,
	RESET_COUPON_CODE: '/general/v1/deleteCoupon',
	REMOVE_UNAVAILABLE_PRODDUCTS_FROM_CART: '/general/v1/removeUnavailableProducts',
	ADD_ITEMS_TO_WISHLIST: '/general/v1/addItemsToWishlist',
	PRODUCT_VARIANT_MAX_MIN: (key: string) => `/general/v1/productVariantMaxMin/${key}`,
	PROCEED_TO_CHECKOUT: `/general/v1/proceedToCheckout`,
};

export const API_ACCOUNT = {
	USER_PROFILE: '/v1/profile',
	CASH_BALANCE: '/v1/cashBalance',
	PRESCRIPTIONS: '/general/v1/userPrescription',
	USER_REFERRER: '/v1/userReferrer/',
	ADDRESS: {
		GET: '/userLocation/v1/',
		DELETE: (id: string | number) => `/userLocation/v1/${id}`,
		UPDATE: (id: string | number) => `/userLocation/v1/${id}`,
		GET_ALL_LOCATION: '/v1/allLocations/'
	},

	GET_NOTIFICATIONS: '/general/v1/notification',
	GET_REQUESTED_PRODUCTS: '/general/v1/productRequestStock',
	GET_REQUESTED_PRODUCTS_IDS: '/general/v1/productStockRequestIds',
	GET_ORDERS: '/general/v1/productOrder',
	CANCEL_ORDER: (orderId: string) => `/general/v1/cancelOrder/${orderId}`,
	GET_ORDER_DETAIL: (orderId: string) => `/general/v1/productOrder/${orderId}`,
	PRODUCT_WISHLIST_TAGS: '/v1/product-wishlist-tags/',
	GET_PRODUCT_WISHLIST: '/v1/product-wishlist/user/',
	PRODUCT_WISHLIST_TOGGLE: (productId: number) => `/v1/product-wishlist/toggle/${productId}?_return_details=1`,
	SUGESSION_PRODUCTS: '/general/v1/suggestedProduct/',
	// GET_TREANDING_PRODUCTS: '/general/v3/product/',
	GET_TREANDING_PRODUCTS: '/general/v3/search/',
	// userLedger,
	USER_LEDGER: '/general/v1/userTransaction',
	NOTIFICATION_UPDATE: (id: string) => `/general/v1/notificationAction/${id}/notificationAction`,
	NOTOFICATION_READ_ALL: '/general/v1/notification/multipleRead',
	USER_WITHDRAW: (id: string) => `/general/v1/cashWithdraw/${id}`,
	REPLACE_PRODUCT: (id: string) => `/general/v1/productOrder/${id}`
};

export const API_PUBLIC = {
	GET_BLOCK: '/general/v3/blocks?_type=web&',
	GET_OFFERS: '/v1/offers/',
	GET_PRODUCT_BY_ID: (id: string) => `/general/v1/product/${id}`,
	GET_SINGLE_COMPANY: (id: string) => `/general/v1/productBrand/${id}`,
	GET_SINGLE_BRAND: (id: string) => `/general/v1/productBrand/${id}`,
	PRODUCT_COMPANIES: `/general/v1/productBrand/`,
	PRODUCT_BRANDS: `/general/v1/productBrand/`,
	GET_PRODUCT_FROM_SEARCH: '/general/v3/search/',
	GET_PRODUCT_FOR_REPLACE: '/general/v1/sameGenericProducts/',
	GET_TAXONOMY_BY_ID: (id: number) => `/general/v1/taxonomy?_v_id=${id}`,
	GET_PRODUCTSLIST_FOR_SEO_CATEGORYPAGE: (category_id:string )=> `/general/v3/search/?_order=p_order_count:desc&_product_category_id=${category_id}&_allow_sales=1`,
	GET_CATEGORY_DATA_BY_PARENT_ID: (parentId: string) =>
		`/general/v1/taxonomy/?_parent_id=${parentId}&_v_machine_name=product_category&_fields=t_id,t_parent_id,t_title,t_has_child,t_icon,t_machine_name`,
	GET_CATEGORY_DATA_BY_NAME: (name: string) =>
		`/general/v1/taxonomy/?_machine_name=${name}&_v_machine_name=product_category&_fields=t_id,t_parent_id,t_title,t_has_child,t_icon,t_machine_name&_orderBy=t_weight`,
	GET_CATEGORY_DATA_BY_ID: (id: string) => `/general/v1/taxonomy/${id}`,
	GET_CATEGORY_BREADCRUMB: (parentId: string) =>
		`/general/v1/taxonomy?_order=ASC&_page=1&_perPage=50000&_v_machine_name=product_category&_reverse_parent=${parentId}&_orderBy=t_parent_id`,
	GET_ADDITIONAL_PAGE: (id: string) => `/v1/page/${id}`,
	GET_MENUS: '/general/v1/menuItem',
	GET_FAQ_HEADER: '/v1/faqsHeaders',
	GET_SINGLE_FAQ: (id: string) => `/v1/faqs/${id}`,
	CHECK_WISHLIST: (id: string) => `/general/v1/globalSingleProductAction/${id}`,

	BLOG_CATEGORY: '/v1/blogCategory',
	GET_BLOG_POSTS: '/general/v1/blogPost/'
};
